nav
  position: sticky
  top: 0
  left: 0
  right: 0
  z-index: 100
  height: 5rem
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: 0 $page-padding-x
  background-color: white

  > div:first-child
    svg
      height: 2.5rem
      width: auto


@media screen and (max-width: 768px)
  nav
    padding: 0 $page-padding-x-mobile
    justify-content: center