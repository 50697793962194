body
  margin: 0
  padding: 0
  font-family: $font-default
  background-color: $background

//begin::Buttons
.btn
  display: inline-flex
  flex-direction: row
  align-items: center
  gap: 8px
  padding: 12px 32px
  font-family: $font-default
  font-weight: 600
  font-size: 16px
  border: 1px solid white
  background-color: white
  color: $color-black
  cursor: pointer
  transition: all .25s ease
  text-decoration: none

.btn-primary
  background-color: white
  border-color: white
  color: $color-black

  svg path
    stroke: $color-black

.btn-secondary
  background-color: transparent
  border-color: white
  color: white

  svg path
    stroke: white

.btn-primary:hover, .btn-secondary:hover
  transform: scale(1.04)
//end::Buttons


.page-padding
  padding: $page-padding-y $page-padding-x

@media screen and (max-width: 768px)
  .page-padding
    padding: $page-padding-y-mobile $page-padding-x-mobile