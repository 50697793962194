@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Regular.otf");
  font-display: swap;
  font-weight: normal;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Bold.otf");
  font-display: swap;
  font-weight: bold;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Thin.otf");
  font-display: swap;
  font-weight: 100;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Light.otf");
  font-display: swap;
  font-weight: 300;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Medium.otf");
  font-display: swap;
  font-weight: 500;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Semibold.otf");
  font-display: swap;
  font-weight: 600;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Bold.otf");
  font-display: swap;
  font-weight: 700;
}
@font-face {
  font-family: "Degular";
  src: url("../fonts/Degular/Degular-Black.otf");
  font-display: swap;
  font-weight: 900;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Degular", Arial, sans-serif;
  background-color: #f5f1ed;
}

.btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 32px;
  font-family: "Degular", Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid white;
  background-color: white;
  color: #0A0A1E;
  cursor: pointer;
  transition: all 0.25s ease;
  text-decoration: none;
}

.btn-primary {
  background-color: white;
  border-color: white;
  color: #0A0A1E;
}
.btn-primary svg path {
  stroke: #0A0A1E;
}

.btn-secondary {
  background-color: transparent;
  border-color: white;
  color: white;
}
.btn-secondary svg path {
  stroke: white;
}

.btn-primary:hover, .btn-secondary:hover {
  transform: scale(1.04);
}

.page-padding {
  padding: 32px 150px;
}

@media screen and (max-width: 768px) {
  .page-padding {
    padding: 24px 24px;
  }
}
nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  background-color: white;
}
nav > div:first-child svg {
  height: 2.5rem;
  width: auto;
}

@media screen and (max-width: 768px) {
  nav {
    padding: 0 24px;
    justify-content: center;
  }
}
header {
  height: calc(100vh - 5rem - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../medias/background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  padding: 32px 150px;
}
header > div {
  border: 1px solid white;
  padding: 24px 32px;
}
header > div h1, header > div p {
  margin: 0;
}
header > div h1 {
  font-size: 40px;
  line-height: 36px;
  margin-bottom: 16px;
}
header > div p {
  margin-bottom: 24px;
}
header .btn-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  header {
    height: calc(100vh - 5rem - 48px);
    padding: 24px 24px;
  }
  header .btn-container {
    flex-direction: column;
  }
}
footer {
  padding: 32px 150px;
  color: #0A0A1E;
  font-family: "Degular", Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
footer a {
  color: #0A0A1E;
}

@media screen and (max-width: 768px) {
  footer {
    padding: 24px 24px;
  }
}
.services-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 32px;
}
.services-container > div {
  border: 1px solid #0A0A1E;
  color: #0A0A1E;
  padding: 12px 24px;
  background-color: white;
}
.services-container > div:first-child {
  grid-area: 1/1/2/2;
}
.services-container > div:nth-child(2) {
  grid-area: 1/2/2/3;
}
.services-container > div:nth-child(3) {
  grid-area: 1/3/3/4;
}
.services-container > div:nth-child(4) {
  grid-area: 2/1/3/3;
}

.meteo-compo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.meteo-city {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}
.meteo-temperature {
  font-size: 48px;
  font-weight: 300;
  margin: 0;
}

@media screen and (max-width: 1076px) {
  .services-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .services-container > div:nth-child(3) {
    grid-area: 2/1/3/3;
  }
  .services-container > div:nth-child(4) {
    grid-area: 3/1/3/3;
  }
}
@media screen and (max-width: 768px) {
  .services-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
  .services-container > div:nth-child(1) {
    grid-area: 1/1/2/2;
  }
  .services-container > div:nth-child(2) {
    grid-area: 2/1/3/2;
  }
  .services-container > div:nth-child(3) {
    grid-area: 3/1/4/2;
  }
  .services-container > div:nth-child(4) {
    grid-area: 4/1/5/2;
  }
}

