header
  height: calc(100vh - 5rem - ($page-padding-y * 2))
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background-image: url("../medias/background.svg")
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  color: white
  padding: $page-padding-y $page-padding-x

  > div
    border: 1px solid white
    padding: 24px 32px

    h1, p
      margin: 0

    h1
      font-size: 40px
      line-height: 36px
      margin-bottom: 16px

    p
      margin-bottom: 24px

  .btn-container
    display: flex
    flex-direction: row
    gap: 16px

@media screen and (max-width: 768px)
  header
    height: calc(100vh - 5rem - $page-padding-x-mobile * 2)
    padding: $page-padding-y-mobile $page-padding-x-mobile

    .btn-container
      flex-direction: column