//Fonts
@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Regular.otf")
  font-display: swap
  font-weight: normal

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Bold.otf")
  font-display: swap
  font-weight: bold

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Thin.otf")
  font-display: swap
  font-weight: 100

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Light.otf")
  font-display: swap
  font-weight: 300

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Medium.otf")
  font-display: swap
  font-weight: 500

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Semibold.otf")
  font-display: swap
  font-weight: 600

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Bold.otf")
  font-display: swap
  font-weight: 700

@font-face
  font-family: "Degular"
  src: url("../fonts/Degular/Degular-Black.otf")
  font-display: swap
  font-weight: 900

$color-primary: #1C54E3
$color-secondary: #0733A3
$color-black: #0A0A1E

//$background: #F8F0E5
$background: #f5f1ed
$background-secondary: #ECE1D1

$page-padding-x: 150px
$page-padding-y: 32px

$page-padding-x-mobile: 24px
$page-padding-y-mobile: 24px

$font-default: "Degular", Arial, sans-serif

@import "modules/general"
@import "modules/nav"
@import "modules/header"
@import "modules/footer"

@import "pages/services"