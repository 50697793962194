.services-container
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(2, 1fr)
  grid-column-gap: 16px
  grid-row-gap: 16px
  margin-top: 32px

  > div
    border: 1px solid $color-black
    color: $color-black
    padding: 12px 24px
    background-color: white

    &:first-child
      grid-area: 1 / 1 / 2 / 2

    &:nth-child(2)
      grid-area: 1 / 2 / 2 / 3

    &:nth-child(3)
      grid-area: 1 / 3 / 3 / 4

    &:nth-child(4)
      grid-area: 2 / 1 / 3 / 3


.meteo
  &-compo
    display: flex
    flex-direction: row
    align-items: center
    gap: 16px

  &-city
    font-weight: 500
    font-size: 18px
    margin: 0

  &-temperature
    font-size: 48px
    font-weight: 300
    margin: 0


@media screen and (max-width: 1076px)
  .services-container
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(3, 1fr)

    > div
      &:nth-child(3)
        grid-area: 2 / 1 / 3 / 3
      &:nth-child(4)
        grid-area: 3 / 1 / 3 / 3


@media screen and (max-width: 768px)
  .services-container
    grid-template-columns: 1fr
    grid-template-rows: repeat(4, 1fr)

    > div
      &:nth-child(1)
        grid-area: 1 / 1 / 2 / 2
      &:nth-child(2)
        grid-area: 2 / 1 / 3 / 2
      &:nth-child(3)
        grid-area: 3 / 1 / 4 / 2
      &:nth-child(4)
        grid-area: 4 / 1 / 5 / 2
