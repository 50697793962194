footer
  padding: $page-padding-y $page-padding-x
  color: $color-black
  font-family: $font-default
  font-weight: 600
  font-size: 16px
  text-align: center
  display: flex
  flex-direction: row
  justify-content: center
  gap: 16px

  a
    color: $color-black

@media screen and (max-width: 768px)
  footer
    padding: $page-padding-y-mobile $page-padding-x-mobile